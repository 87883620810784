import React from "react";

import { BsLightningCharge } from "react-icons/bs";

import {
  FaBuilding,
  FaDoorOpen,
  FaPlug,
  FaLightbulb,
  FaToolbox,
  FaTowerObservation,
  FaTrash,
  FaTruckMedical,
  FaTv,
  FaTruckFront,
  FaVanShuttle,
  FaCouch,
  FaCross,
  FaElevator,
  FaFlask,
  FaHeart,
  FaHotel,
  FaKey,
  FaBath,
  FaBasketball,
  FaBabyCarriage,
  FaBoltLightning,
  FaBed,
  FaBicycle,
  FaCarRear,
  FaGlobe,
  FaMusic,
  FaSun,
  FaWrench,
  FaVideo,
  FaToilet,
} from "react-icons/fa6";

import LightIcon from "../areas/areas-icons/LightIcon";
import ConferenceRoomIcon from "../create-room/commercial-room-icons/ConferenceRoomIcon";
import ComputerIcon from "../circuitNodes/icons/ComputerIcon";
import CondensorIcon from "../circuitNodes/icons/CondensorIcon";
import DistributionIcon from "../circuitNodes/icons/DistributionIcon";
import EVIcon from "../circuitNodes/icons/EVIcon";
import FridgeIcon from "../circuitNodes/icons/FridgeIcon";
import GasCooker from "../circuitNodes/icons/GasCooker";
import GearsIcon from "../circuitNodes/icons/GearsIcon";
import HVACIcon from "../circuitNodes/icons/HVACIcon";
import OvenIcon from "../circuitNodes/icons/OvenIcon";
import PlugIcon from "../circuitNodes/icons/PlugIcon";
import DatabaseIcon from "../circuitNodes/icons/DatabaseIcon";
import TransformerIcon from "../circuitNodes/icons/TransformerIcon";
import TransmittingIcon from "../circuitNodes/icons/TransmittingIcon";
import ServerIcon from "../circuitNodes/icons/ServerIcon";
import NetworkIcon from "../circuitNodes/icons/NetworkIcon";
import GreehouseIcon from "../circuitNodes/icons/GreehouseIcon";
import GarageTagIcon from "../circuitNodes/icons/GarageTagIcon";
import ElectricityIcon from "../circuitNodes/icons/ElectricityIcon";

export type OrganisationTagIconTag = "circuitNode" | "room";
interface OrganisationTagIconDefinition {
  key: string;
  component: JSX.Element;
  tags: OrganisationTagIconTag[];
}

interface OrganisationTagIconsSettings {
  size?: number;
  color?: string;
  prioritize?: OrganisationTagIconTag;
}
export const organisationTagIcons = ({
  size = 35,
  color = "black",
  prioritize,
}: OrganisationTagIconsSettings): OrganisationTagIconDefinition[] => {
  return [
    { component: <LightIcon fill={color} width={size} height={size} />, key: "light", tags: ["circuitNode"] },
    { component: <PlugIcon fill={color} size={size} />, key: "plug", tags: ["circuitNode"] },
    { component: <HVACIcon fill={color} size={size} />, key: "hvac", tags: ["circuitNode"] },
    { component: <DistributionIcon fill={color} size={size} />, key: "distribution", tags: ["circuitNode"] },
    { component: <CondensorIcon fill={color} size={size} />, key: "condensor", tags: ["circuitNode"] },
    { component: <TransformerIcon fill={color} size={size} />, key: "transformer", tags: ["circuitNode"] },
    { component: <FridgeIcon fill={color} size={size} />, key: "fridge", tags: ["circuitNode"] },
    { component: <OvenIcon fill={color} size={size} />, key: "oven", tags: ["circuitNode"] },
    { component: <ComputerIcon fill={color} size={size} />, key: "computer", tags: ["circuitNode"] },
    { component: <EVIcon fill={color} size={size} />, key: "electricVehicle", tags: ["circuitNode"] },
    { component: <GasCooker fill={color} size={size} />, key: "gasCooker", tags: ["circuitNode"] },
    { component: <GearsIcon fill={color} size={size} />, key: "gears", tags: ["circuitNode"] },
    { component: <TransmittingIcon fill={color} size={size} />, key: "transmitting", tags: ["circuitNode"] },
    { component: <DatabaseIcon fill={color} size={size} />, key: "database", tags: ["circuitNode"] },
    { component: <ServerIcon fill={color} size={size} />, key: "server", tags: ["circuitNode"] },
    { component: <NetworkIcon fill={color} size={size} />, key: "network", tags: ["circuitNode"] },
    { component: <GreehouseIcon fill={color} size={size} />, key: "greenhouse", tags: ["circuitNode"] },
    { component: <GarageTagIcon fill={color} size={size} />, key: "garage", tags: ["circuitNode"] },
    { component: <ElectricityIcon fill={color} size={size} />, key: "electricity", tags: ["circuitNode"] },
    {
      component: <ConferenceRoomIcon color={color} width={size} height={size} />,
      key: "conference",
      tags: ["circuitNode"],
    },
    {
      component: <FaBuilding color={color} size={size} />,
      key: "building-fa6",
      tags: ["room"],
    },
    {
      component: <FaDoorOpen color={color} size={size} />,
      key: "doorOpen-fa6",
      tags: ["room"],
    },
    { component: <FaPlug color={color} size={size} />, key: "plug-fa6", tags: ["room"] },
    {
      component: <FaLightbulb color={color} size={size} />,
      key: "lightBulb-fa6",
      tags: ["room"],
    },
    {
      component: <FaToolbox color={color} size={size} />,
      key: "toolbox-fa6",
      tags: ["room"],
    },
    {
      component: <FaTowerObservation color={color} size={size} />,
      key: "towerObservation-fa6",
      tags: ["room"],
    },
    {
      component: <FaTrash color={color} size={size} />,
      key: "trash-fa6",
      tags: ["room"],
    },
    {
      component: <FaTruckMedical color={color} size={size} />,
      key: "truckMedical-fa6",
      tags: ["room"],
    },
    {
      component: <FaTv color={color} size={size} />,
      key: "tv-fa6",
      tags: ["room"],
    },
    {
      component: <FaTruckFront color={color} size={size} />,
      key: "truckFront-fa6",
      tags: ["room"],
    },
    {
      component: <FaVanShuttle color={color} size={size} />,
      key: "vanShuttle-fa6",
      tags: ["room"],
    },
    {
      component: <FaCouch color={color} size={size} />,
      key: "couch-fa6",
      tags: ["room"],
    },
    {
      component: <FaCross color={color} size={size} />,
      key: "cross-fa6",
      tags: ["room"],
    },
    {
      component: <FaElevator color={color} size={size} />,
      key: "elevator-fa6",
      tags: ["room"],
    },
    {
      component: <FaFlask color={color} size={size} />,
      key: "flask-fa6",
      tags: ["room"],
    },
    {
      component: <FaHeart color={color} size={size} />,
      key: "heart-fa6",
      tags: ["room"],
    },
    {
      component: <FaHotel color={color} size={size} />,
      key: "hotel-fa6",
      tags: ["room"],
    },
    {
      component: <FaKey color={color} size={size} />,
      key: "key-fa6",
      tags: ["room"],
    },
    {
      component: <FaBath color={color} size={size} />,
      key: "bath-fa6",
      tags: ["room"],
    },
    {
      component: <FaBasketball color={color} size={size} />,
      key: "basketball-fa6",
      tags: ["room"],
    },
    {
      component: <FaBabyCarriage color={color} size={size} />,
      key: "babyCarriage-fa6",
      tags: ["room"],
    },
    {
      component: <FaBoltLightning color={color} size={size} />,
      key: "boltLightning-fa6",
      tags: ["room"],
    },
    {
      component: <FaBed color={color} size={size} />,
      key: "bed-fa6",
      tags: ["room"],
    },
    {
      component: <FaBicycle color={color} size={size} />,
      key: "bicycle-fa6",
      tags: ["room"],
    },
    {
      component: <FaCarRear color={color} size={size} />,
      key: "carRear-fa6",
      tags: ["room"],
    },
    {
      component: <FaGlobe color={color} size={size} />,
      key: "globe-fa6",
      tags: ["room"],
    },
    {
      component: <FaMusic color={color} size={size} />,
      key: "music-fa6",
      tags: ["room"],
    },
    {
      component: <FaSun color={color} size={size} />,
      key: "sun-fa6",
      tags: ["room"],
    },
    {
      component: <FaWrench color={color} size={size} />,
      key: "wrench-fa6",
      tags: ["room"],
    },
    {
      component: <FaVideo color={color} size={size} />,
      key: "video-fa6",
      tags: ["room"],
    },
    {
      component: <FaToilet color={color} size={size} />,
      key: "toilet-fa6",
      tags: ["room"],
    },
  ].sort((a, b) => {
    if (prioritize && a.tags.includes(prioritize) && !b.tags.includes(prioritize)) {
      return -1;
    } else if (prioritize && !a.tags.includes(prioritize) && b.tags.includes(prioritize)) {
      return 1;
    } else {
      return a.key < b.key ? -1 : a.key > b.key ? 1 : 0;
    }
  }) as OrganisationTagIconDefinition[];
};
export const getOrganisationTagIconForIconName = (iconName: string, size = 35, color = "black") => {
  const icon = organisationTagIcons({ size, color }).find((icon) => icon.key === iconName);
  if (icon) {
    return icon.component;
  }

  return <BsLightningCharge width={size} height={size} fill={color} />;
};
