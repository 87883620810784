import * as React from "react";
const CondensorIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 31 24">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M.672 7.635c0-.299.243-.541.542-.541h12.624a.542.542 0 0 1 0 1.083H1.214a.542.542 0 0 1-.542-.542Zm0 5.729c0-.3.243-.542.542-.542h12.624a.542.542 0 0 1 0 1.084H1.214a.542.542 0 0 1-.542-.542Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M2.955 1.435a1.2 1.2 0 0 0-1.199 1.199v15.732a1.2 1.2 0 0 0 1.2 1.2h25.339a1.2 1.2 0 0 0 1.199-1.2V2.634a1.2 1.2 0 0 0-1.2-1.2H2.956ZM20.97 20.649h7.325a2.283 2.283 0 0 0 2.282-2.283V2.634A2.283 2.283 0 0 0 28.296.35H2.955A2.283 2.283 0 0 0 .672 2.634v15.732a2.283 2.283 0 0 0 2.283 2.283h.12v1.86c0 .3.242.542.541.542h2.402c.299 0 .542-.243.542-.542v-1.86h1.318v1.86c0 .3.242.542.542.542h12.008c.3 0 .542-.243.542-.542v-1.86Zm-1.083 0H8.96v1.318h10.926V20.65Zm-14.411 0H4.158v1.318h1.318V20.65Zm.069-4.99c0-.3.242-.543.542-.543h3.645a.542.542 0 0 1 0 1.084H6.087a.542.542 0 0 1-.542-.542Zm.02 2.154c0-.3.244-.542.543-.542H9.71a.542.542 0 1 1 0 1.084H6.108a.542.542 0 0 1-.542-.542Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16.023 5.52a6.647 6.647 0 0 0-2.236 4.98c0 .568.07 1.12.205 1.646 1.047-1.74 2.76-2.667 4.506-2.167a.542.542 0 1 1-.298 1.042c-1.313-.376-2.877.455-3.686 2.509a6.663 6.663 0 0 0 5.936 3.634c1.11 0 2.157-.272 3.077-.752-2.127-.452-3.64-1.867-3.64-3.784a.542.542 0 1 1 1.083 0c0 1.452 1.416 2.8 3.907 2.853a6.68 6.68 0 0 0 2.033-6.624c-1.039 1.736-2.727 2.665-4.476 2.164a.542.542 0 0 1 .299-1.042c1.31.376 2.845-.45 3.654-2.508a6.663 6.663 0 0 0-9.027-2.876c2.113.462 3.61 1.882 3.61 3.789a.542.542 0 0 1-1.083 0c0-1.446-1.402-2.795-3.864-2.864Zm-3.32 4.98a7.747 7.747 0 1 1 15.494 0 7.747 7.747 0 0 1-15.494 0Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M20.45 8.97a1.53 1.53 0 1 0 0 3.06 1.53 1.53 0 0 0 0-3.06Zm-2.613 1.53a2.613 2.613 0 1 1 5.226 0 2.613 2.613 0 0 1-5.226 0Z"
      clipRule="evenodd"
    />
  </svg>
);
export default CondensorIcon;
