import * as React from "react";
const GarageTagIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 35 33">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M7.478 14.915a.54.54 0 0 1 .54-.54h19.214a.54.54 0 0 1 .54.54v17.197a.54.54 0 0 1-.54.54H8.018a.54.54 0 0 1-.54-.54V14.915Zm1.08 3.407v1.786h18.134v-1.786H8.558Zm18.134-1.08H8.558v-1.787h18.134v1.787Zm0 3.946H8.558v1.787h18.134v-1.787Zm0 2.867H8.558v1.787h18.134v-1.787Zm0 2.867H8.558v1.818h18.134v-1.818Zm0 2.898H8.558v1.752h18.134V29.82Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M17.328 4.478a.54.54 0 0 1 .593-.001l12.66 8.281c.152.1.244.27.244.452v18.902a.54.54 0 0 1-.54.54H5.07a.54.54 0 0 1-.54-.54V13.21a.54.54 0 0 1 .243-.45l12.555-8.282Zm.298 1.097L5.61 13.501v18.07h24.135V13.503L17.626 5.575Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M17.32.438a.54.54 0 0 1 .592-.002l16.84 11.017c.153.1.245.27.245.452v3.01a.54.54 0 0 1-.54.54h-4.172a.54.54 0 1 1 0-1.08h3.632v-2.178l-16.3-10.663-16.28 10.74-.003 2.1h3.667a.54.54 0 0 1 0 1.08H.793a.54.54 0 0 1-.54-.54l.005-2.932a.54.54 0 0 1 .243-.45L17.319.438Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M13.482 9.873a.54.54 0 0 1 .54-.54h7.206a.54.54 0 0 1 .54.54v2.402a.54.54 0 0 1-.54.54h-7.206a.54.54 0 0 1-.54-.54V9.873Zm1.08.54v1.322h6.126v-1.322h-6.126Z"
      clipRule="evenodd"
    />
  </svg>
);
export default GarageTagIcon;
