import * as React from "react";
const DatabaseIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 31 23">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M.744 16.386c0-1.068.867-1.935 1.935-1.935h25.905c1.069 0 1.936.867 1.936 1.935v4.37a1.936 1.936 0 0 1-1.936 1.935H2.68a1.936 1.936 0 0 1-1.935-1.936v-4.369Zm1.935-.757a.758.758 0 0 0-.757.757v4.37c0 .417.34.756.757.756h25.905c.418 0 .758-.339.758-.757v-4.369a.758.758 0 0 0-.758-.757H2.68Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      d="M14.868 17.057c.371 0 .673.29.673.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.673-.649c0-.358.301-.649.673-.649ZM16.971 17.057c.372 0 .674.29.674.65 0 .357-.302.648-.674.648a.662.662 0 0 1-.673-.649c0-.358.302-.649.674-.649ZM19.076 17.057c.371 0 .673.29.673.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.674-.649c0-.358.302-.649.674-.649ZM21.18 17.057c.37 0 .672.29.672.65 0 .357-.301.648-.673.648a.662.662 0 0 1-.673-.649c0-.358.302-.649.673-.649ZM23.283 17.057c.372 0 .673.29.673.65 0 .357-.301.648-.673.648a.662.662 0 0 1-.673-.649c0-.358.302-.649.673-.649ZM25.387 17.057c.371 0 .673.29.673.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.674-.649c0-.358.302-.649.674-.649ZM27.49 17.057c.372 0 .674.29.674.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.674-.649c0-.358.302-.649.674-.649ZM14.868 19.16c.371 0 .673.292.673.65 0 .358-.302.65-.673.65a.662.662 0 0 1-.673-.65c0-.358.301-.65.673-.65ZM16.971 19.16c.372 0 .674.292.674.65 0 .358-.302.65-.674.65a.662.662 0 0 1-.673-.65c0-.358.302-.65.674-.65ZM19.076 19.16c.371 0 .673.292.673.65 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65ZM21.18 19.16c.37 0 .672.292.672.65 0 .358-.301.65-.673.65a.662.662 0 0 1-.673-.65c0-.358.302-.65.673-.65ZM23.283 19.16c.372 0 .673.292.673.65 0 .358-.301.65-.673.65a.662.662 0 0 1-.673-.65c0-.358.302-.65.673-.65ZM25.387 19.16c.371 0 .673.292.673.65 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65ZM27.49 19.16c.372 0 .674.292.674.65 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.443 17.862a.876.876 0 1 0 .001 1.751.876.876 0 0 0 0-1.751Zm-1.818.875a1.818 1.818 0 1 1 3.637.001 1.818 1.818 0 0 1-3.637-.001ZM.744 9.318c0-1.069.867-1.936 1.935-1.936h25.905c1.069 0 1.936.867 1.936 1.936v4.369a1.936 1.936 0 0 1-1.936 1.935H2.68a1.936 1.936 0 0 1-1.935-1.935v-4.37Zm1.935-.758a.758.758 0 0 0-.757.758v4.369c0 .417.34.757.757.757h25.905c.418 0 .758-.34.758-.758V9.319a.758.758 0 0 0-.758-.758H2.68Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      d="M14.868 9.989c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.673-.65c0-.358.301-.648.673-.648ZM16.971 9.989c.372 0 .674.29.674.649 0 .358-.302.649-.674.649a.662.662 0 0 1-.673-.65c0-.358.302-.648.674-.648ZM19.076 9.989c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.358.302-.648.674-.648ZM21.18 9.989c.37 0 .672.29.672.649 0 .358-.301.649-.673.649a.662.662 0 0 1-.673-.65c0-.358.302-.648.673-.648ZM23.283 9.989c.372 0 .673.29.673.649 0 .358-.301.649-.673.649a.662.662 0 0 1-.673-.65c0-.358.302-.648.673-.648ZM25.387 9.989c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.358.302-.648.674-.648ZM27.49 9.989c.372 0 .674.29.674.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.358.302-.648.674-.648ZM14.868 12.092c.371 0 .673.291.673.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.673-.649c0-.358.301-.649.673-.649ZM16.971 12.092c.372 0 .674.291.674.65 0 .357-.302.648-.674.648a.662.662 0 0 1-.673-.649c0-.358.302-.649.674-.649ZM19.076 12.092c.371 0 .673.291.673.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.674-.649c0-.358.302-.649.674-.649ZM21.18 12.092c.37 0 .672.291.672.65 0 .357-.301.648-.673.648a.662.662 0 0 1-.673-.649c0-.358.302-.649.673-.649ZM23.283 12.092c.372 0 .673.291.673.65 0 .357-.301.648-.673.648a.662.662 0 0 1-.673-.649c0-.358.302-.649.673-.649ZM25.387 12.092c.371 0 .673.291.673.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.674-.649c0-.358.302-.649.674-.649ZM27.49 12.092c.372 0 .674.291.674.65 0 .357-.302.648-.673.648a.662.662 0 0 1-.674-.649c0-.358.302-.649.674-.649Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.443 10.793a.876.876 0 1 0 .001 1.752.876.876 0 0 0 0-1.752Zm-1.818.875a1.818 1.818 0 1 1 3.637.002 1.818 1.818 0 0 1-3.637-.002ZM.744 2.25c0-1.07.867-1.936 1.935-1.936h25.905c1.069 0 1.936.867 1.936 1.935v4.369a1.936 1.936 0 0 1-1.936 1.935H2.68A1.936 1.936 0 0 1 .744 6.618V2.249Zm1.935-.758a.758.758 0 0 0-.757.757v4.369c0 .418.34.757.757.757h25.905c.418 0 .758-.34.758-.757V2.249a.758.758 0 0 0-.758-.757H2.68Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      d="M14.868 2.92c.371 0 .673.29.673.649 0 .358-.302.65-.673.65a.662.662 0 0 1-.673-.65c0-.358.301-.65.673-.65ZM16.971 2.92c.372 0 .674.29.674.649 0 .358-.302.65-.674.65a.662.662 0 0 1-.673-.65c0-.358.302-.65.674-.65ZM19.076 2.92c.371 0 .673.29.673.649 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65ZM21.18 2.92c.37 0 .672.29.672.649 0 .358-.301.65-.673.65a.662.662 0 0 1-.673-.65c0-.358.302-.65.673-.65ZM23.283 2.92c.372 0 .673.29.673.649 0 .358-.301.65-.673.65a.662.662 0 0 1-.673-.65c0-.358.302-.65.673-.65ZM25.387 2.92c.371 0 .673.29.673.649 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65ZM27.49 2.92c.372 0 .674.29.674.649 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65Z"
    />
    <path
      fill={fill}
      d="M27.49 2.92c.372 0 .674.29.674.649 0 .358-.302.65-.673.65a.662.662 0 0 1-.674-.65c0-.358.302-.65.674-.65ZM14.868 5.024c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.673-.65c0-.357.301-.648.673-.648ZM16.971 5.024c.372 0 .674.29.674.649 0 .358-.302.649-.674.649a.662.662 0 0 1-.673-.65c0-.357.302-.648.674-.648ZM19.076 5.024c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.357.302-.648.674-.648Z"
    />
    <path
      fill={fill}
      d="M19.076 5.024c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.357.302-.648.674-.648ZM21.18 5.024c.37 0 .672.29.672.649 0 .358-.301.649-.673.649a.662.662 0 0 1-.673-.65c0-.357.302-.648.673-.648ZM23.283 5.024c.372 0 .673.29.673.649 0 .358-.301.649-.673.649a.662.662 0 0 1-.673-.65c0-.357.302-.648.673-.648ZM25.387 5.024c.371 0 .673.29.673.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.357.302-.648.674-.648ZM27.49 5.024c.372 0 .674.29.674.649 0 .358-.302.649-.673.649a.662.662 0 0 1-.674-.65c0-.357.302-.648.674-.648Z"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.443 3.724a.876.876 0 1 0 .002 1.752.876.876 0 0 0-.002-1.752ZM3.625 4.6a1.818 1.818 0 1 1 3.637.001A1.818 1.818 0 0 1 3.625 4.6Z"
      clipRule="evenodd"
    />
  </svg>
);
export default DatabaseIcon;
