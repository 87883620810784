import * as React from "react";
const ServerIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 31 26">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M3.504 19.468a.8.8 0 0 0-.799.8v2.87a.8.8 0 0 0 .8.8h8.056a.8.8 0 0 0 .8-.8v-2.87a.8.8 0 0 0-.8-.8H3.504Zm-1.879.8a1.88 1.88 0 0 1 1.88-1.88h8.056a1.88 1.88 0 0 1 1.88 1.88v2.87a1.88 1.88 0 0 1-1.88 1.88H3.504a1.88 1.88 0 0 1-1.879-1.88v-2.87ZM19.233 19.468a.8.8 0 0 0-.8.8v2.87a.8.8 0 0 0 .8.8h8.012a.8.8 0 0 0 .8-.8v-2.87a.8.8 0 0 0-.8-.8h-8.012Zm-1.88.8a1.88 1.88 0 0 1 1.88-1.88h8.012a1.88 1.88 0 0 1 1.88 1.88v2.87a1.88 1.88 0 0 1-1.88 1.88h-8.012a1.88 1.88 0 0 1-1.88-1.88v-2.87ZM2.304 1.084a.8.8 0 0 0-.8.8v5.99a.8.8 0 0 0 .8.8h26.142a.8.8 0 0 0 .8-.8v-5.99a.8.8 0 0 0-.8-.8H2.304Zm-1.88.8a1.88 1.88 0 0 1 1.88-1.88h26.142a1.88 1.88 0 0 1 1.88 1.88v5.99a1.88 1.88 0 0 1-1.88 1.88H2.304a1.88 1.88 0 0 1-1.88-1.88v-5.99Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M3.29 4.88a2.112 2.112 0 1 1 4.222 0 2.112 2.112 0 0 1-4.223 0ZM5.4 3.847a1.032 1.032 0 1 0 0 2.063 1.032 1.032 0 0 0 0-2.063ZM12.347 11.362a.54.54 0 0 1 .223.73l-2.553 4.804a.54.54 0 1 1-.954-.507l2.553-4.803a.54.54 0 0 1 .73-.224Zm6.087.012a.54.54 0 0 1 .73.223l2.553 4.804a.54.54 0 0 1-.953.507l-2.553-4.804a.54.54 0 0 1 .223-.73ZM13.712 3.597a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 1 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 1 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63ZM13.712 6.17a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 1 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 1 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Zm2.455 0a.63.63 0 0 1 .63-.63.625.625 0 0 1 .015 0 .63.63 0 0 1 0 1.26.625.625 0 0 1-.015 0 .63.63 0 0 1-.63-.63Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ServerIcon;
