const FridgeIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 24 24">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M17.25 2H6.75C5.784 2 5 2.84 5 3.875v16.25C5 21.16 5.784 22 6.75 22h10.5c.966 0 1.75-.84 1.75-1.875V3.875C19 2.839 18.216 2 17.25 2Zm.583 18.125c0 .345-.26.625-.583.625H6.75c-.322 0-.583-.28-.583-.625V9.5h8.166v7.188c0 .172.13.312.292.312h.583c.161 0 .292-.14.292-.313V9.5h2.333v10.625Zm0-11.875H15.5V6.062c0-.172-.13-.312-.292-.312h-.583c-.161 0-.292.14-.292.313V8.25H6.167V3.875c0-.345.26-.625.583-.625h10.5c.322 0 .583.28.583.625V8.25Z"
      clipRule="evenodd"
    />
  </svg>
);
export default FridgeIcon;
