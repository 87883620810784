import * as React from "react";
const EVIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 35 28">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M28.602 8.55c.299.001.541.244.54.543l-.006 3.654a.542.542 0 1 1-1.083-.002l.006-3.653c0-.3.243-.542.543-.541ZM8.91 7.453c.115-.015.23-.022.346-.022h10.307a2.87 2.87 0 0 1 .293.015c.704.073 1.374.405 1.751.968l4.993 7.16a.542.542 0 0 1-.444.852H19.81a.437.437 0 0 1-.04 0H5.424a.542.542 0 0 1-.519-.7l2.073-6.798c.212-.808.978-1.32 1.816-1.459a.541.541 0 0 1 .116-.016Zm-.522 1.301c-.2.133-.324.3-.364.454a.556.556 0 0 1-.006.025l-1.863 6.11h2.233V8.753Zm1.083 6.588V8.515h9.778v6.827H9.471Zm10.862 0h4.785L20.715 9.03a1.027 1.027 0 0 0-.383-.333v6.646ZM30.06 17.731c.251-.104.52-.157.792-.157v.542-.542h1.336a.542.542 0 1 1 0 1.084h-1.337a.975.975 0 0 0-.693.287h-.001a.977.977 0 0 0-.287.694c0 .543.44.982.981.982h2.899a.542.542 0 0 1 0 1.084H30.85a2.065 2.065 0 0 1-2.064-2.064l.541-.001h-.541a2.059 2.059 0 0 1 .603-1.46l.384.382-.383-.383c.191-.192.419-.344.67-.448ZM.347 20.988c0-.3.243-.542.542-.542h2.391a.542.542 0 0 1 0 1.084H.888a.542.542 0 0 1-.542-.542Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.605 16.427a4.176 4.176 0 0 0-4.175 4.175v3.234h4.342a.542.542 0 0 1 0 1.084H.888a.542.542 0 0 1-.542-.542v-3.776a5.26 5.26 0 0 1 5.26-5.259h21.578a7.27 7.27 0 0 1 7.27 7.27v1.765c0 .299-.242.541-.542.541h-5.015a.542.542 0 0 1 0-1.083h4.473v-1.223a6.186 6.186 0 0 0-6.186-6.186H5.605Zm9.643 2.867c0-.3.243-.542.542-.542h2.02a.542.542 0 0 1 0 1.084h-2.02a.542.542 0 0 1-.542-.542Zm-4.27 5.085c0-.3.243-.542.542-.542h11.627a.542.542 0 1 1 0 1.084H11.52a.542.542 0 0 1-.542-.542Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.229 24.378a3.417 3.417 0 1 1 6.834 0 3.417 3.417 0 0 1-6.834 0Zm3.417-2.334a2.334 2.334 0 1 0 0 4.667 2.334 2.334 0 0 0 0-4.667Zm13.959 2.334a3.417 3.417 0 1 1 6.834 0 3.417 3.417 0 0 1-6.834 0Zm3.417-2.334a2.334 2.334 0 1 0 0 4.667 2.334 2.334 0 0 0 0-4.667ZM8.104 24.378a.542.542 0 1 1 .543.542h-.001a.542.542 0 0 1-.542-.542Zm17.376 0c0-.3.242-.542.541-.542h.001a.542.542 0 1 1 0 1.084.542.542 0 0 1-.542-.542ZM26.833.203c.3 0 .542.243.542.542l-.002 1.895 2.463.002V.746a.542.542 0 0 1 1.084 0v1.896l1.866.001a.542.542 0 1 1 0 1.084h-.568l-.001 2.295a3.615 3.615 0 1 1-7.23-.004l.002-2.296h-.567a.542.542 0 1 1 .001-1.084l1.867.001V.744c.001-.299.244-.541.543-.541Zm-.76 3.52-.002 2.295a2.531 2.531 0 0 0 5.062.003l.002-2.295-5.062-.003Z"
      clipRule="evenodd"
    />
  </svg>
);
export default EVIcon;
