const ComputerIcon = ({ size, fill = "black" }: { size: number; fill?: string }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 36 35">
    <g fill={fill} clipPath="url(#a)">
      <path d="M28.532 20.905H6.718a.513.513 0 0 1-.513-.512V9.364c0-.283.23-.512.513-.512h21.814c.283 0 .513.23.513.512v11.029c0 .283-.23.512-.513.512ZM7.23 19.88h20.788V9.877H7.231V19.88Z" />
      <path d="M32.845 28.181H2.404a.512.512 0 0 1-.44-.25l-1.138-1.9-.015-.027a.512.512 0 0 1-.046-.355.258.258 0 0 1 .01-.036v-.001a.518.518 0 0 1 .139-.222l3.259-3.181V7.33c0-.283.23-.513.512-.513h25.878c.284 0 .513.23.513.513v14.877l3.26 3.18c.066.063.113.14.139.224l.008.035v.001a.508.508 0 0 1-.047.355l-.014.026-1.138 1.9a.509.509 0 0 1-.44.252Zm-30.15-1.025h29.86l.526-.88H2.169l.525.88Zm-.171-1.905h30.201l-2.37-2.313-25.502.04-2.33 2.273Zm2.675-3.34h24.852V7.845H5.2V21.91Z" />
    </g>
    <defs>
      {/* <clip id="a"> */}
      <path fill="#fff" d="M.125 0h35v35h-35z" />
      {/* </ClipPath> */}
    </defs>
  </svg>
);
export default ComputerIcon;
