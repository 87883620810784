const TransformerIcon = ({ size, fill }: { size?: number; fill?: string }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 36 35">
    <mask id="a" width={30} height={22} x={3} y={6} maskUnits="userSpaceOnUse">
      <path fill={"white"} d="M32.958 6.863H3.792V27.28h29.166V6.863Z" />
    </mask>
    <g fill={fill} mask="url(#a)">
      <path d="M31.703 15.749h-2.615V12.75a.474.474 0 0 0-.461-.486h-2.615V7.349a.474.474 0 0 0-.46-.486.474.474 0 0 0-.462.486v2.755H11.66V7.39a.474.474 0 0 0-.462-.486.474.474 0 0 0-.46.486v4.875H8.122a.474.474 0 0 0-.461.486v2.998H5.047c-.692 0-1.255.593-1.255 1.323s.563 1.323 1.255 1.323h2.615v2.997c0 .268.206.486.46.486h2.615v4.876c0 .268.207.485.461.485a.474.474 0 0 0 .461-.485v-2.715H25.09v2.755c0 .268.207.486.461.486a.474.474 0 0 0 .461-.486v-4.916h2.615a.474.474 0 0 0 .461-.486v-2.997h2.615c.692 0 1.255-.594 1.255-1.323 0-.73-.563-1.323-1.255-1.323Zm-23.12 5.158v-7.67h2.154v7.67H8.584Zm3.076 2.16V11.077H25.09v11.992H11.66Zm16.507-2.16h-2.154v-7.67h2.154v7.67Z" />
      <path d="M20.18 16.545h-2.67l1.774-2.418a.504.504 0 0 0-.082-.682.446.446 0 0 0-.647.087l-2.35 3.2a.507.507 0 0 0-.048.511.46.46 0 0 0 .414.272h2.497l-2.25 2.372a.505.505 0 0 0 0 .687c.09.095.208.143.325.143a.449.449 0 0 0 .326-.143l3.037-3.2a.505.505 0 0 0 .1-.53.462.462 0 0 0-.426-.3Z" />
    </g>
  </svg>
);
export default TransformerIcon;
