import * as React from "react";
const GasCooker = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 31 27">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.552 5.804a.542.542 0 0 1 .365.513c0 .4.228.716.624 1.253.362.492.834 1.145.834 2.07a2 2 0 0 1-4 0c0-.537.159-1.086.355-1.567.2-.486.452-.939.672-1.293a10.834 10.834 0 0 1 .533-.78l.01-.012.002-.003.001-.002.427.334-.427-.334a.542.542 0 0 1 .604-.179Zm-.347 1.742a7.437 7.437 0 0 0-.472.938c-.17.418-.274.818-.274 1.156a.917.917 0 0 0 1.833 0c0-.534-.257-.93-.624-1.427l-.067-.091c-.126-.17-.269-.361-.396-.576Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M15.552.855a.542.542 0 0 1 .365.512c0 1.013.579 1.798 1.326 2.813l.052.07c.74 1.005 1.59 2.201 1.59 3.88a3.51 3.51 0 0 1-7.02 0c0-.974.286-1.997.663-2.93a17.36 17.36 0 0 1 1.3-2.526 21.73 21.73 0 0 1 1.094-1.607l.019-.024.005-.007.002-.001v-.001l.427.333-.427-.333a.542.542 0 0 1 .604-.179Zm-.8 2.387c.116-.19.23-.366.335-.527l-.335.527Zm.335-.527c.286.76.77 1.414 1.214 2.014l.122.164C17.167 5.904 17.8 6.841 17.8 8.13a2.426 2.426 0 0 1-4.852 0c0-.778.232-1.653.584-2.524.35-.864.805-1.691 1.219-2.364M12.908 19.565a2.467 2.467 0 1 1 4.933 0 2.467 2.467 0 0 1-4.933 0Zm2.467-1.383a1.383 1.383 0 1 0 0 2.766 1.383 1.383 0 0 0 0-2.766Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M9.489 12.843a.777.777 0 0 0-.778.777v1.74h6.122v-1.64a.542.542 0 1 1 1.084 0v1.64h6.121v-1.74a.777.777 0 0 0-.777-.777h-2.283a.542.542 0 1 1 0-1.084h2.283c1.028 0 1.861.834 1.861 1.861v1.74h5.838a1.367 1.367 0 0 1 1.367 1.367v5.555a1.367 1.367 0 0 1-1.367 1.367h-1.034v1.86c0 .3-.243.542-.542.542H22.58a.542.542 0 0 1-.541-.542v-1.86H8.71v1.86c0 .3-.242.542-.541.542H3.366a.542.542 0 0 1-.542-.542v-1.86H1.79a1.368 1.368 0 0 1-1.368-1.367v-5.555A1.367 1.367 0 0 1 1.79 15.36h5.838v-1.74c0-1.027.833-1.86 1.86-1.86h2.284a.542.542 0 0 1 0 1.083H9.49Zm-1.37 3.6h-6.33a.284.284 0 0 0-.283.284v5.555a.284.284 0 0 0 .284.283h27.17a.284.284 0 0 0 .284-.284v-5.554a.284.284 0 0 0-.284-.284h-6.329a.566.566 0 0 1-.101 0h-7.104a.566.566 0 0 1-.102 0H8.22a.566.566 0 0 1-.101 0Zm18.723 7.206h-3.72v1.318h3.72v-1.318Zm-19.214 0h-3.72v1.318h3.72v-1.318Zm-3.603-4.145c0-.299.243-.541.542-.541H8.17a.542.542 0 0 1 0 1.083H4.567a.542.542 0 0 1-.542-.542Zm18.014 0c0-.299.242-.541.541-.541h3.603a.542.542 0 0 1 0 1.083H22.58a.542.542 0 0 1-.541-.542Z"
      clipRule="evenodd"
    />
  </svg>
);
export default GasCooker;
