const PlugIcon = ({ size, fill = "black" }: { size?: number; fill?: string }) => (
  <svg width={size} height={size} fill="none" viewBox="0 0 36 35">
    <path
      fill={fill}
      d="M11.878 11.48a.519.519 0 0 0 .519-.52V4.737a.645.645 0 0 1 1.29 0v6.224a.519.519 0 1 0 1.038 0V4.737c0-.928-.756-1.683-1.683-1.683-.928 0-1.683.756-1.683 1.683v6.224c0 .286.232.518.519.518Zm8.941-.14a.519.519 0 0 0 .52-.518V4.599a.645.645 0 0 1 1.29 0v6.224a.519.519 0 1 0 1.037 0V4.599c0-.928-.755-1.683-1.683-1.683S20.3 3.671 20.3 4.599v6.224c0 .286.233.518.52.518Z"
    />
    <path
      fill={fill}
      d="M17.534 32.082h.003a.519.519 0 0 0 .516-.522l-.019-4.263h.294c.982 0 1.782-.8 1.782-1.783v-.009h.302a5.871 5.871 0 0 0 5.864-5.866v-5.513h.526c.982 0 1.782-.8 1.782-1.783 0-.983-.8-1.783-1.783-1.783l-18.617.029c-.982 0-1.781.8-1.781 1.782 0 .983.8 1.783 1.782 1.783l.565-.001v5.499a5.872 5.872 0 0 0 5.866 5.865h.3c.001.982.8 1.78 1.782 1.78h.298l.02 4.268c0 .285.232.517.518.517Zm1.538-6.574v.006c0 .41-.334.745-.744.745h-1.63a.745.745 0 0 1-.745-.745l3.12-.006Zm6.166-11.38v5.51a4.833 4.833 0 0 1-4.828 4.828l-5.796.014a4.833 4.833 0 0 1-4.826-4.828v-5.5l15.45-.024Zm1.564-2.529a.745.745 0 0 1-.001 1.49l-18.617.027a.745.745 0 0 1 .001-1.489l18.617-.028Z"
    />
  </svg>
);
export default PlugIcon;
