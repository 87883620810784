import * as React from "react";
const TransmittingIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 36 31">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M2.788 18.241a.54.54 0 0 1 .093.758 4.65 4.65 0 0 0-.99 2.874v.002a4.649 4.649 0 0 0 .99 2.874.54.54 0 0 1-.85.666 5.73 5.73 0 0 1-1.22-3.54 5.729 5.729 0 0 1 1.22-3.542.54.54 0 0 1 .757-.092Zm1.44 1.07a.54.54 0 0 1 .092.758 2.918 2.918 0 0 0-.622 1.804v.002c0 .654.218 1.29.622 1.805a.54.54 0 1 1-.85.666 3.998 3.998 0 0 1-.852-2.472 3.998 3.998 0 0 1 .852-2.472.54.54 0 0 1 .758-.091ZM33.047 18.241a.54.54 0 0 1 .758.092 5.728 5.728 0 0 1 1.219 3.541 5.728 5.728 0 0 1-1.22 3.541.54.54 0 1 1-.85-.666c.643-.82.991-1.832.99-2.874v-.002a4.649 4.649 0 0 0-.99-2.874.54.54 0 0 1 .093-.758Zm-1.44 1.07a.54.54 0 0 1 .758.091c.553.706.853 1.576.852 2.472a3.998 3.998 0 0 1-.852 2.472.54.54 0 1 1-.85-.666c.404-.515.623-1.15.622-1.805v-.002a2.918 2.918 0 0 0-.622-1.804.54.54 0 0 1 .092-.758ZM9.591 1.635a.543.543 0 0 0-.54.54v11.262c0 .295.244.54.54.54H26.16c.296 0 .54-.245.54-.54V2.175a.543.543 0 0 0-.54-.54H9.59Zm-1.62.54c0-.893.728-1.62 1.62-1.62H26.16c.892 0 1.62.727 1.62 1.62v11.262c0 .892-.728 1.62-1.62 1.62H9.59c-.892 0-1.62-.728-1.62-1.62V2.175Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M9.783 2.855a.54.54 0 0 1 .54-.54h15.104a.54.54 0 0 1 .54.54v8.7a.54.54 0 0 1-.54.54H10.323a.54.54 0 0 1-.54-.54v-8.7Zm1.08.54v7.62h14.024v-7.62H10.863Zm5.425 10.948a.54.54 0 0 1 .511-.366h2.151a.54.54 0 0 1 .512.366v.003l.005.013.019.052.074.197c.066.166.16.39.275.623.116.235.247.466.385.653.145.198.261.298.332.333a.54.54 0 0 1-.239 1.024h-4.877a.54.54 0 0 1-.239-1.024c.071-.035.188-.135.333-.333.137-.187.269-.418.385-.653a8.294 8.294 0 0 0 .368-.872l.002-.008.002-.005.001-.002Zm.511.174v.54h.374c-.071.179-.17.41-.29.653-.072.145-.154.3-.244.45h-1.203v.54l.24.485c.303-.15.544-.415.725-.662.084-.115.164-.238.238-.362h2.472c.074.124.153.247.238.362.18.247.422.512.725.662l.24-.484v-.54H19.11a6.665 6.665 0 0 1-.244-.451 9.276 9.276 0 0 1-.29-.653h.373v-.54l-.511.173v.002l.002.005.007.018.022.064a8.479 8.479 0 0 0 .106.278h-1.403a5.725 5.725 0 0 0 .106-.278l.023-.064.003-.007.003-.01.002-.006v-.001l.001-.001-.512-.173Zm0 0c-.511-.173-.511-.174-.51-.174l.51.174ZM9.08 25.782a.54.54 0 0 1 .54-.54h.01a.54.54 0 0 1 0 1.08h-.01a.54.54 0 0 1-.54-.54Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.907 18.092c0-.953.772-1.726 1.725-1.726h3.97c.954 0 1.726.773 1.726 1.726v8.285c0 .953-.772 1.725-1.725 1.725h-.258l.435 1.563a.54.54 0 0 1-.52.685H7.975a.54.54 0 0 1-.52-.685l.434-1.563h-.258a1.726 1.726 0 0 1-1.725-1.725v-8.285Zm1.08 6.49v1.795c0 .356.289.645.645.645h3.971c.356 0 .646-.289.646-.645v-1.795H6.987Zm5.261-1.08H6.987v-5.41c0-.357.289-.646.645-.646h3.97c.357 0 .646.29.646.646v5.41Zm-2.023 4.6H9.01l-.325 1.168h1.863l-.324-1.168ZM25.63 25.782a.54.54 0 0 1 .54-.54h.01a.54.54 0 1 1 0 1.08h-.01a.54.54 0 0 1-.54-.54Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M22.465 18.165c0-.993.805-1.799 1.799-1.799h3.824c.993 0 1.799.806 1.799 1.799v8.139c0 .993-.806 1.798-1.799 1.798h-.184l.434 1.563a.54.54 0 0 1-.52.685h-3.284a.54.54 0 0 1-.52-.685l.434-1.563h-.184a1.799 1.799 0 0 1-1.8-1.799v-8.138Zm1.08 6.417v1.721c0 .397.322.72.718.72h3.825a.719.719 0 0 0 .719-.72v-1.72h-5.262Zm5.262-1.08h-5.262v-5.337c0-.397.322-.719.718-.719h3.825c.397 0 .719.322.719.719v5.337Zm-2.024 4.6h-1.214l-.325 1.168h1.863l-.324-1.168Z"
      clipRule="evenodd"
    />
  </svg>
);
export default TransmittingIcon;
