import * as React from "react";
const GreehouseIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 26 31">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M19.188 15.174c-1 .037-2.229.654-3.186 1.832-.958 1.18-1.31 2.508-1.142 3.495 1-.037 2.229-.654 3.187-1.833.957-1.179 1.31-2.507 1.141-3.494Zm-5.348 5.745c-.342-1.416.163-3.171 1.32-4.596 1.235-1.52 2.99-2.389 4.517-2.212.21.024.385.167.452.367.485 1.458-.007 3.354-1.242 4.874-1.148 1.413-2.746 2.264-4.193 2.234-.616.845-.794 1.156-.939 1.544a5.26 5.26 0 0 0-.28 2.528.542.542 0 1 1-1.074.143 6.344 6.344 0 0 1 .339-3.05c.2-.538.46-.956 1.1-1.832Zm-6.407-2.363c.071.655.49 1.45 1.275 2.087.784.638 1.647.885 2.303.82-.071-.654-.49-1.448-1.275-2.086-.784-.638-1.647-.885-2.303-.82Zm-.71-.952c1.126-.375 2.561.01 3.697.932 1.135.923 1.805 2.249 1.668 3.428a.542.542 0 0 1-.367.452c-1.126.375-2.561-.01-3.697-.932-1.135-.923-1.805-2.249-1.668-3.428a.542.542 0 0 1 .367-.452Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M11.204 21.485a.542.542 0 0 1 .763.07 6.678 6.678 0 0 1 1.358 2.78v.001c.104.458.155.925.155 1.394a.542.542 0 0 1-1.084-.001c0-.388-.042-.775-.128-1.153a5.594 5.594 0 0 0-1.135-2.329.542.542 0 0 1 .071-.762Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M12.507.666a.542.542 0 0 1 .742 0l11.937 11.23a.54.54 0 0 1 .17.395v17.62c0 .299-.242.541-.541.541H20.37a.546.546 0 0 1-.209 0H5.768a.546.546 0 0 1-.209 0H.931a.542.542 0 0 1-.542-.541V12.295a.54.54 0 0 1 .195-.416l7.924-7.453a.543.543 0 0 1 .025-.023L12.507.666ZM8.35 6.063l-6.05 5.69h6.05v-5.69Zm1.084 5.69v-6.71l3.444-3.239 3.433 3.23v6.72H9.434Zm-.552 1.084H24.273v16.532h-3.514a3.059 3.059 0 0 0-4.094-2.306 4.595 4.595 0 0 0-3.7-1.866 4.595 4.595 0 0 0-3.7 1.866 3.059 3.059 0 0 0-4.094 2.306H1.474V12.837h7.409ZM6.284 29.369h13.363a1.975 1.975 0 0 0-1.896-1.422c-.353 0-.699.094-1.002.274a.542.542 0 0 1-.743-.194 3.515 3.515 0 0 0-3.04-1.746c-1.297 0-2.43.7-3.041 1.747a.542.542 0 0 1-.744.193 1.959 1.959 0 0 0-1-.274h-.002c-.898 0-1.656.6-1.895 1.422Zm17.169-17.616h-6.058V6.054l6.058 5.7Z"
      clipRule="evenodd"
    />
  </svg>
);
export default GreehouseIcon;
