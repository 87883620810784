import * as React from "react";
const OvenIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 46 45">
    <mask
      id="a"
      width={46}
      height={45}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M.625 0h45v45h-45V0Z" />
    </mask>
    <g fill={fill} fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
      <path d="M15.26 13.876c0-.299.243-.541.543-.541h4.233a.542.542 0 1 1 0 1.083h-4.234a.542.542 0 0 1-.541-.542Z" />
      <path d="M11.775 10.491c0-.3.243-.542.542-.542h21.616c.3 0 .542.243.542.542v21.616c0 .3-.243.542-.542.542h-.659v1.86c0 .3-.242.542-.542.542H13.518a.542.542 0 0 1-.542-.542v-1.86h-.659a.542.542 0 0 1-.542-.542V10.491Zm21.616 21.074V11.033H12.86v20.532h20.532ZM14.06 32.65v1.318h18.13V32.65H14.06Z" />
      <path d="M15.42 16.512a.542.542 0 0 1 .383-.159h14.645c.299 0 .541.242.541.541l.013 9.809a.542.542 0 0 1-.542.542H15.815a.542.542 0 0 1-.542-.541l-.012-9.808c0-.144.057-.282.158-.384Zm.925.925.011 8.724h13.562l-.011-8.724H16.345Zm2.578 2.012c0-.299.242-.541.542-.541h7.32a.542.542 0 0 1 0 1.083h-7.32a.542.542 0 0 1-.542-.541Z" />
    </g>
  </svg>
);
export default OvenIcon;
