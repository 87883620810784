import * as React from "react";
const GearsIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 46 45">
    <mask
      id="a"
      width={46}
      height={45}
      x={0}
      y={0}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path fill="#fff" d="M.625 0h45v45h-45V0Z" />
    </mask>
    <g fill={fill} fillRule="evenodd" clipRule="evenodd" mask="url(#a)">
      <path d="M18.038 7.482a10.276 10.276 0 0 1 3.587.568c.203.07.349.25.377.463l.19 1.457 1.29-.703a.567.567 0 0 1 .596.032 10.28 10.28 0 0 1 2.566 2.568.567.567 0 0 1 .033.595l-.704 1.291 1.46.19a.567.567 0 0 1 .463.375c.403 1.153.595 2.369.565 3.59a.567.567 0 0 1-.323.498l-1.328.63 1.068 1.01c.155.148.215.37.154.575a10.146 10.146 0 0 1-1.65 3.24.567.567 0 0 1-.555.213l-1.446-.27.27 1.444a.567.567 0 0 1-.214.555c-.97.739-2.066 1.298-3.234 1.65a.567.567 0 0 1-.576-.153l-1.01-1.07-.633 1.33a.567.567 0 0 1-.5.324 10.18 10.18 0 0 1-1.819-.125 10.173 10.173 0 0 1-1.767-.443.567.567 0 0 1-.375-.462l-.19-1.46-1.294.704a.567.567 0 0 1-.595-.032 10.188 10.188 0 0 1-2.565-2.569.567.567 0 0 1-.031-.594l.702-1.29-1.46-.19a.567.567 0 0 1-.462-.375 10.125 10.125 0 0 1-.566-3.59.567.567 0 0 1 .323-.498l1.328-.632-1.068-1.01a.567.567 0 0 1-.153-.573c.349-1.17.907-2.268 1.647-3.24a.567.567 0 0 1 .555-.213l1.448.27-.27-1.445a.567.567 0 0 1 .214-.556 10.276 10.276 0 0 1 3.234-1.649.567.567 0 0 1 .576.153l1.011 1.068.63-1.327a.567.567 0 0 1 .501-.324Zm.37 1.134-.832 1.752a.567.567 0 0 1-.924.146l-1.334-1.41a9.144 9.144 0 0 0-2.266 1.156l.357 1.908a.567.567 0 0 1-.662.661l-1.91-.357a8.972 8.972 0 0 0-1.154 2.268l1.41 1.333a.567.567 0 0 1-.146.924l-1.753.834c.012.866.15 1.71.397 2.514l1.926.25a.567.567 0 0 1 .425.833l-.928 1.704a9.054 9.054 0 0 0 1.796 1.8l1.706-.93a.567.567 0 0 1 .834.425l.25 1.926c.407.124.822.22 1.242.286h.002c.42.067.844.105 1.269.112l.834-1.754a.567.567 0 0 1 .924-.146l1.335 1.41a9.057 9.057 0 0 0 2.265-1.156l-.356-1.907a.567.567 0 0 1 .662-.662l1.908.358c.49-.698.879-1.462 1.155-2.269l-1.41-1.334a.567.567 0 0 1 .146-.924l1.753-.832a8.992 8.992 0 0 0-.396-2.513l-1.927-.251a.567.567 0 0 1-.425-.834l.929-1.704a9.148 9.148 0 0 0-1.799-1.8l-1.703.93a.567.567 0 0 1-.834-.425l-.25-1.924a9.141 9.141 0 0 0-2.515-.398Z" />
      <path d="M18.673 15.085a2.631 2.631 0 0 0-3.01 2.187l-.56-.089.56.089a2.63 2.63 0 1 0 3.01-2.187Zm-4.13 2.01a3.764 3.764 0 1 1 7.436 1.178 3.764 3.764 0 0 1-7.436-1.178ZM29.427 22.501a6.496 6.496 0 0 1 2.265.36c.128.044.22.157.237.291l.12.92.816-.444a.358.358 0 0 1 .375.02c.633.44 1.181.99 1.621 1.622a.358.358 0 0 1 .02.376l-.444.815.922.12c.135.018.248.11.292.237.255.728.376 1.496.357 2.267a.358.358 0 0 1-.204.314l-.838.398.674.639a.358.358 0 0 1 .097.362 6.404 6.404 0 0 1-1.041 2.046.358.358 0 0 1-.351.135l-.913-.171.17.912a.358.358 0 0 1-.135.35 6.438 6.438 0 0 1-2.042 1.042.358.358 0 0 1-.364-.096l-.638-.675-.4.84a.358.358 0 0 1-.315.204 6.433 6.433 0 0 1-2.264-.36.358.358 0 0 1-.237-.29l-.12-.923-.817.445a.358.358 0 0 1-.376-.02 6.434 6.434 0 0 1-1.62-1.623.358.358 0 0 1-.02-.375l.444-.815-.922-.12a.358.358 0 0 1-.291-.236 6.394 6.394 0 0 1-.358-2.267.358.358 0 0 1 .204-.315l.838-.399-.674-.637a.358.358 0 0 1-.097-.362c.22-.74.573-1.433 1.04-2.046a.358.358 0 0 1 .351-.135l.914.171-.17-.913a.358.358 0 0 1 .135-.35 6.488 6.488 0 0 1 2.042-1.042c.13-.04.27-.002.364.096l.638.675.398-.838a.358.358 0 0 1 .317-.205Zm.233.716-.525 1.107a.358.358 0 0 1-.584.092l-.842-.89c-.509.175-.99.42-1.431.73l.225 1.204a.358.358 0 0 1-.418.418l-1.206-.226a5.66 5.66 0 0 0-.728 1.432l.89.842a.358.358 0 0 1-.093.584l-1.106.526c.007.547.094 1.08.25 1.588l1.217.157a.358.358 0 0 1 .268.527l-.586 1.076c.323.43.705.813 1.134 1.136l1.077-.587a.358.358 0 0 1 .527.268l.159 1.217a5.727 5.727 0 0 0 1.586.251L30 33.561a.358.358 0 0 1 .583-.092l.843.89c.508-.174.99-.42 1.43-.73l-.225-1.204a.358.358 0 0 1 .418-.417l1.205.225c.31-.44.555-.922.73-1.432l-.89-.843a.358.358 0 0 1 .092-.583l1.107-.526a5.675 5.675 0 0 0-.25-1.587l-1.217-.158a.358.358 0 0 1-.269-.526l.587-1.076a5.775 5.775 0 0 0-1.136-1.137l-1.076.587a.358.358 0 0 1-.526-.269l-.159-1.215a5.779 5.779 0 0 0-1.588-.252Z" />
      <path d="M29.828 27.302a1.662 1.662 0 0 0-1.901 1.381l-.354-.056.354.056a1.661 1.661 0 1 0 1.9-1.38Zm-2.608 1.27a2.377 2.377 0 1 1 4.696.744v-.001a2.377 2.377 0 0 1-4.696-.744Z" />
    </g>
  </svg>
);
export default GearsIcon;
