import * as React from "react";
const NetworkIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 28 31">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M5.652.765a.542.542 0 0 1-.025.765 12.594 12.594 0 0 0-4 9.22c0 3.636 1.538 6.913 4 9.218a.542.542 0 1 1-.741.791A13.677 13.677 0 0 1 .543 10.75c0-3.948 1.672-7.508 4.343-10.01a.542.542 0 0 1 .766.026Zm2.993 3.213a.542.542 0 0 1-.025.765 8.204 8.204 0 0 0-2.606 6.006 8.204 8.204 0 0 0 2.606 6.007.542.542 0 1 1-.74.79 9.287 9.287 0 0 1-2.95-6.796 9.288 9.288 0 0 1 2.95-6.798.542.542 0 0 1 .765.026ZM22.848.765a.542.542 0 0 0 .025.765c2.461 2.306 4 5.583 4 9.22 0 3.636-1.539 6.913-4 9.218a.542.542 0 1 0 .74.791 13.677 13.677 0 0 0 4.343-10.01c0-3.948-1.672-7.508-4.343-10.01a.542.542 0 0 0-.765.026Zm-2.993 3.213a.542.542 0 0 0 .025.765 8.204 8.204 0 0 1 2.605 6.006 8.204 8.204 0 0 1-2.605 6.007.542.542 0 0 0 .74.79 9.287 9.287 0 0 0 2.95-6.796 9.287 9.287 0 0 0-2.95-6.798.542.542 0 0 0-.765.026Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M9.311 10.75a4.929 4.929 0 1 1 5.461 4.9v14.202a.542.542 0 0 1-1.084 0V15.648a4.93 4.93 0 0 1-4.377-4.899Zm4.929-3.846a3.845 3.845 0 1 0 0 7.69 3.845 3.845 0 0 0 0-7.69Z"
      clipRule="evenodd"
    />
  </svg>
);
export default NetworkIcon;
