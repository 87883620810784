import * as React from "react";
const ElectricityIcon = ({ size, fill }: { size: number; fill: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 26 31">
    <path
      fill={fill}
      fillRule="evenodd"
      d="M10 4.6a.54.54 0 0 1-.135.752l-6.814 4.745h6.506a.54.54 0 0 1 .308.983l-6.814 4.745h6.506a.54.54 0 0 1 0 1.08H1.33a.54.54 0 0 1-.309-.983l6.814-4.745H1.331a.54.54 0 0 1-.309-.983l8.226-5.728A.54.54 0 0 1 10 4.6Zm6.75 0a.54.54 0 0 1 .752-.134l8.226 5.728a.54.54 0 0 1-.308.983h-8.226a.54.54 0 0 1 0-1.08h6.505l-6.814-4.745a.54.54 0 0 1-.134-.752Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M16.75 10.329a.54.54 0 0 1 .752-.135l8.226 5.728a.54.54 0 0 1 .232.443v1.8a.54.54 0 1 1-1.08 0v-1.26h-7.686a.54.54 0 1 1 0-1.08h6.505l-6.814-4.745a.54.54 0 0 1-.134-.752Zm-15.42-.232a.54.54 0 0 1 .54.54v1.8a.54.54 0 1 1-1.08 0v-1.8a.54.54 0 0 1 .54-.54Zm24.09 0a.54.54 0 0 1 .54.54v1.8a.54.54 0 1 1-1.08 0v-1.8a.54.54 0 0 1 .54-.54ZM1.33 15.825a.54.54 0 0 1 .54.54v1.8a.54.54 0 0 1-1.08 0v-1.8a.54.54 0 0 1 .54-.54ZM13.376 15.825a.54.54 0 0 1 .381.159l3.82 3.818a.54.54 0 0 1 0 .764l-3.82 3.819a.54.54 0 0 1-.763 0l-3.82-3.819a.54.54 0 0 1 0-.764l3.82-3.819a.54.54 0 0 1 .382-.158Zm-3.056 4.36 3.056 3.054 3.055-3.055-3.056-3.055-3.055 3.055Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M13.376 8.188a.54.54 0 0 1 .381.158l3.82 3.819a.54.54 0 0 1 0 .764l-3.82 3.818a.54.54 0 0 1-.763 0l-3.82-3.818a.54.54 0 0 1 0-.764l3.82-3.819a.54.54 0 0 1 .382-.158Zm-3.056 4.359 3.056 3.055 3.055-3.055-3.056-3.055-3.055 3.055Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M13.376.55a.54.54 0 0 1 .381.158l3.82 3.82a.54.54 0 0 1 0 .763l-3.82 3.819a.54.54 0 0 1-.763 0l-3.82-3.82a.54.54 0 0 1 0-.763l3.82-3.819a.54.54 0 0 1 .382-.158ZM10.32 4.91l3.056 3.055L16.43 4.91l-3.055-3.055-3.056 3.055Zm3.437 18.712 5.908 5.908a.54.54 0 1 1-.763.764l-5.526-5.526L7.85 30.29a.437.437 0 0 1-.016.015l-.001.002a.54.54 0 0 1-.742-.785l5.902-5.902a.54.54 0 0 1 .763 0Z"
      clipRule="evenodd"
    />
    <path
      fill={fill}
      fillRule="evenodd"
      d="M9.017 4.909a.54.54 0 0 1 .54-.54h7.637a.54.54 0 0 1 .54.54v15.215l2.077 9.673a.54.54 0 0 1-.527.654H7.467a.54.54 0 0 1-.528-.654l2.077-9.67V4.909Zm7.655 15.415a.543.543 0 0 1-.018-.14V5.45h-6.557v14.735a.54.54 0 0 1-.012.114L8.136 29.37h10.48l-1.944-9.047Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ElectricityIcon;
